<template>
    <page title="消息详情" class="message-detail-page" :hasBack="true" textAlign="left" :backHome="backHome">
        <div class="message-wrapper">
            <text-wrapper class="has-line" label="孩子姓名">
                <div class="des">{{ messageDetail.studentName }}</div>
            </text-wrapper>
            <text-wrapper class="has-line" label="所属班级">
                <div class="des">{{ messageDetail.className }}</div>
            </text-wrapper>
            <text-wrapper class="has-line" label="通行时间">
                <div class="des">{{ messageDetail.behaviorTime }}</div>
            </text-wrapper>
            <text-wrapper class="has-line" label="通行方向">
                <div class="des">{{ messageDetail.direction }}</div>
            </text-wrapper>
            <text-wrapper class="has-line" label="设备名称">
                <div class="des">{{ messageDetail.deviceName }}</div>
            </text-wrapper>
            <text-wrapper class="has-line" label="备注说明">
                <div class="des">{{ messageDetail.remark }}</div>
            </text-wrapper>
        </div>
        <div class="phone-wrapper">
            <div class="title">
                抓拍照片:
            </div>
            <van-image class="photo"  fit="cover" :src="messageDetail.imageUrl" />
        </div>
    </page>
</template>
<script>
    import { ref } from 'vue';
    import { useRoute } from 'vue-router';
    import Page from '@/components/page/Page';
    import TextWrapper from '@/components/text/text';
    import { Image as VanImage } from 'vant';
    import commonRequest from '@/services';

    export default {
        name: 'MessageDetail',
        components: {
            [Page.name]: Page,
            [TextWrapper.name]: TextWrapper,
            [VanImage.name]: VanImage,
        },
        setup() {
            const route = useRoute();
            const messageDetail = ref({
                // studentName: '王二毛'
            });
            commonRequest({ action: 11021, messageId: route.query.id }).then((res) => {
                if (res.data) {
                    messageDetail.value = res.data;
                }
            });

            return {
                messageDetail,
                backHome: route.query.backHome
            };
        },
    };
</script>
<style lang="less" scoped>
    .message-wrapper {
        border-radius: 5px;
        /deep/ .text-wrapper {
            margin-bottom: 0;
            border-radius: 0;
        }
        /deep/ .label {
            font-size: 13px;
            color: #999999;
        }
        .des {
            height: 100%;
            display: flex;
            align-items: center;
            margin-left: 5px;
            font-size: 14px;
            font-family: PingFang SC, serif;
            font-weight: bold;
            color: #333333;
            flex: 1;
            position: relative;
            height: 100%;
        }
        .has-line {
            position: relative;
            &::after {
                content: '';
                left: 10px;
                right: 0;
                height: 1px;
                position: absolute;
                bottom: 0;
                background: #f2f2f2;
            }
        }
    }
    .phone-wrapper {
        height: 218px;
        background: #FFFFFF;
        border-radius: 5px;
        margin-top: 10px;
        padding-left: 10px;
        .title {
            font-size: 13px;
            font-family: PingFang SC, serif;
            color: #999999;
            padding: 17px 0 26px 0;
        }
        .photo {
            width: 74px;
            height: 109px;
        }
    }

</style>
